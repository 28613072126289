import React from 'react';
import DataTable from 'react-data-table-component';
import DataComponent, { DataComponentState } from '../DataComponent';
import DatePicker from "react-datepicker";
import strftime from "strftime"
import {Button, Box, Select,MenuItem, FormControl } from "@mui/material"
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss"
import PageCard from 'components/PageCard';
import ExcelIcon from 'components/ExcelIcon';

function cc(row: any, c: string) {
  if (row[`color_${c}`]) {
    return <span style={{ color: row[`color_${c}`] }}>{row[`count_${c}`]}</span>
  } else {
    return row[`count_${c}`]
  }
}

export type FunnelProps = {
  type?: string
  partner: string
  startDate?: Date
  endDate?: Date,
  version?: string
  title?:string
  versionFilter?:boolean,
  baseUrl?:string
}

interface FunnelState extends DataComponentState {
  startDate?: Date
  endDate?: Date
  version?: string
  pctMode : string
}

class Funnel extends DataComponent<FunnelProps, FunnelState> {
  baseUrl: string = "data/p/funnel"
  funnelType: string = "partner"
  _title : string = "Funnel"
  
  get url() {
    const baseUrl = this.props.baseUrl || this.baseUrl
    console.log(">>>>",this.props.baseUrl)
    let url = `${baseUrl}?type=${this.funnelType}&partner=${this.props.partner}`
    console.log(">>>> url: ",url)

    if (this.state.startDate) {
      const startDate = strftime("%Y-%m-%d", this.state.startDate)
      url += "&start=" + startDate
    }
    if (this.state.endDate) {
      const endDate = strftime("%Y-%m-%d", this.state.endDate)
      url += "&end=" + endDate
    }
    if (this.state.version) {
        url += "&ver=" + this.state.version
    }
  
    return url
  }

  get xlurl() {
    return this.url + "&xl=1"
  }

  constructor(props: FunnelProps) {
    super(props)

    this.state = {
        ...this.state,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        version: this.props.version || "latest",
        pctMode: "p2"
    }

    if (props.type) {
      this.funnelType = props.type
    } else {
      // if @care in partner name, then it's a care funnel
      if (props.partner.includes("@care")) {
        this.funnelType = "care"
      } else {
        this.funnelType = "partner"
      }
    }
  }

  /*
  device  : string="all"
  readonly deviceOptions = [
      { value: "all", label: "All" },
      { value: "Computer", label: "Computer" },
      { value: "Tablet", label: "Tablet" },
      { value: "Mobile", label: "Mobile" },
  ]
  */


  //startDate = weekAgo()
  //endDate   = new Date()

  onLoad() {
  }

  /*
  onDateChange = (dates: any) => {
      console.log(dates)
      this.startDate = dates[0]
      this.endDate = dates[1]
  }*/
  setStart = (date: any) => {
    this.setState({ startDate: date })
    //this.reload()
  }
  setEnd = (date: any) => {
    this.setState({ endDate: date })
    //this.reload()
  }
  setPctMode = (pctMode: string) => {
    this.setState({ pctMode: pctMode })
    //this.reload()
  }

  setVersion = (version: string) => {
    console.log("setVersion", version)
    this.setState({ version: version }, this.reload)
  }


  /*
  values2url = (value: any) : string => {
      const res = value.map((v: any) => `countries=${v.value}`).join("&")
      print(res)
      return res
  }
  */

  filter = (values: any, action: any) => {
    if (values) {
      //this.url = this.baseUrl + "?" + value.map((v: any) => `countries=${v.value}`).join("&")
      //this.countries = this.values2url(values)
      this.reload()
    }
  }

  /*
  onDeviceSelect = (values: any, action: any) => {
      console.log("onDeviceSelect", values, action)
      this.device = values.label
      this.reload()
  }
  */

  async reload() {
    this.loadData()
  }

  eventNames(): string[] {
    return this.data.table.map((row: any) => row.event)
  }
  loading_text() {
    return "Loading funnel data"
  }
  // loading_placeholder() {
  //     return (<h3>Loading funnel data... </h3>)
  // }

  title(){
    return <>{this.props.title ||this._title}</>
  }

  VersionsMenu() {
    if (!this.data.versions) {
        return null
    }
    console.log("versions:",this.data.versions)
    return ( 
      <Select value={this.state.version} onChange={(e)=>this.setVersion(e.target.value)}>
        <MenuItem value="all">all</MenuItem>
        <MenuItem value="latest">3 latest versions</MenuItem>
            {this.data.versions.map((ver:string)=><MenuItem key={ver} value={ver}>v{ver}</MenuItem>)}        
       </Select>
    )
  }


  _render() {
    const w = "auto"
    const w2 = "auto"

    let columns: any[] = [
      {
        name: 'event', wrap: true, width:"auto",minWidth:"auto" , grow:5,
        cell: (row: any) => { return row.optional ? row.event : <b>{row.event}</b> },
      }
    ]

    for (let c of this.data.periods) {
      // skip all zero columns
      if (! this.data.table[0][`count_${c}`]) {
        continue
      }
      columns.push({
        name: c, style: { fontWeight: "bold" }, width: w,
        selector: (row: any) => { return cc(row, c) }
      })
      if (this.state.pctMode === "p1") {
          columns.push({ name: "%", selector: (row: any) => { return row[`pct_${c}`] }, width: w2 })
      }
      if (this.state.pctMode === "p2") {
        columns.push({ name: "%%", selector: (row: any) => { return row[`totpct_${c}`] }, width: w2 })
      }

    }
    
    const VersionsMenu = this.VersionsMenu()
    return (
      <Box>
        <h3 className="page-title"><FilterAltOutlinedIcon/>{this.title()}</h3>
        <PageCard>

          <div className="funnel-date-range-filter" >

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                   <Select value={this.state.pctMode} onChange={(e)=>this.setPctMode(e.target.value)}>
                     <MenuItem value="p0">hide % columns</MenuItem>
                     <MenuItem value="p1">show % columns</MenuItem>
                     <MenuItem value="p2">show % from total</MenuItem>
                   </Select>
                </FormControl>


            { this.props.versionFilter && VersionsMenu && <>
                 <div className="date-range-label">Version</div>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    { VersionsMenu }
                </FormControl>
            </>}

            <div className="date-range-label">Date Range</div>
            <div className="date-range-control from">
              <label>From</label>
              <DatePicker dateFormat="yyyy/MM/dd" selected={this.state.startDate} onChange={(date: Date) => this.setStart(date)} />
            </div>
            <div className="date-range-control">
              <label>To</label>
              <DatePicker dateFormat="yyyy/MM/dd" selected={this.state.endDate} popperPlacement="bottom-end" onChange={(date: Date) => this.setEnd(date)} />
            </div>
            <Button variant="contained" size="small" disableElevation onClick={() => this.reload()}>filter</Button>
            <ExcelIcon url={this.xlurl} filename="Funnel"/>
          </div>

          <DataTable
            data={this.data.table}
            striped highlightOnHover fixedHeader dense
            columns={columns}
            noHeader
          />
        </PageCard>
        </Box>

    );

  }

}


export default Funnel;
