import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';



type UsersPerMonthProps = {
    partner : string | string[]
    start? : Date
    end? : Date
    periodSubtitle? : string

}

function UsersPerMonth(props:UsersPerMonthProps) {

    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url="users-per-month/" 
            partner={props.partner} 
            aux_url_params={ {start:startDate, end:endDate, periodSubtitle:props.periodSubtitle} }
            title="Unique users per month" 
            subtitle="" 
        />
    )
}

export default UsersPerMonth;
